import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  accountProfileImage,
  accountProfileImageButton,
  imageContainer,
  boxContainer,
  formGrid,
  guardianRelationshipGrid,
  selectLabel,
} from "./Guardian.styles";
import ErasableTextField from "../../../ErasableTextField";
import lookupService from "../../../../service/lookupService";
import { font17 } from "../../../sharedStyles";
import PhoneInput from "../../../PhoneInput";

export default function GeneralInfo({
  requiredMessage,
  renderError,
  currentGuardian,
  isGuardianView,
  languages,
}) {
  const { t } = useTranslation("common", { keyPrefix: "guardianDrawer" });

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [guadiansList, setGuadiansList] = useState([]);

  const [profilePictureSrc, setProfilePictureSrc] = useState(
    "/assets/default_avatar.jpg"
  );

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePictureSrc(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (currentGuardian?.image_url) {
      setProfilePictureSrc(currentGuardian?.image_url.url);
    }
  }, [currentGuardian]);

  const getSelectDefaultValue = (field, defaultValue) =>
    currentGuardian && currentGuardian[field]
      ? currentGuardian[field]
      : defaultValue;

  const getGuardiansList = async () => {
    const response = await lookupService.fetchGuardians();
    setGuadiansList(response.data.data);
  };

  const setDisplay = isGuardianView && "none";

  useEffect(() => {
    getGuardiansList();
  }, []);

  return (
    <>
      <Grid container item sx={guardianRelationshipGrid}>
        <Grid item container direction="row" sm={7}>
          {isGuardianView && (
            <Grid container item columnSpacing={2} rowSpacing={2}>
              <Grid item sm={10}>
                <FormControl fullWidth>
                  <InputLabel id="preferred_language" shrink sx={selectLabel}>
                    {t("preferredLanguage")}
                  </InputLabel>
                  <Select
                    labelId="preferred_language"
                    id="preferred_language"
                    label={t("preferredLanguage")}
                    defaultValue={
                      currentGuardian.preferred_language || "English"
                    }
                    {...register("preferred_language", {
                      required: requiredMessage,
                    })}
                  >
                    {languages.map((language) => (
                      <MenuItem key={language.id} value={language.name}>
                        {t(language.name.toLowerCase(), {
                          keyPrefix: "languages",
                        })}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            item
            columnSpacing={2}
            rowSpacing={2}
            style={{ display: setDisplay }}
          >
            <Grid item sm={9}>
              <FormControl fullWidth error={errors?.relationship}>
                <InputLabel id="relationship" shrink sx={selectLabel}>
                  Relationship to student*
                </InputLabel>
                <Select
                  labelId="relationship"
                  id="relationship"
                  label="Relationship to student"
                  defaultValue={getSelectDefaultValue("relationship", "")}
                  {...register("relationship", {
                    required: requiredMessage,
                  })}
                >
                  <MenuItem disabled value="">
                    Select Relationship
                  </MenuItem>
                  {guadiansList.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {errors?.relationship &&
                renderError(errors?.relationship.message)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="column" sm={5}>
          <Grid container item sm={12} sx={imageContainer}>
            <Box sx={boxContainer}>
              <img
                alt="profile"
                src={profilePictureSrc}
                style={accountProfileImage}
              />
              <Button
                variant="outlined"
                sx={accountProfileImageButton}
                component="label"
              >
                {t("add")}
                <input
                  hidden
                  accept=".jpeg, .jpg, .png"
                  type="file"
                  {...register("profilePicture", {
                    onChange: handleProfilePictureChange,
                  })}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item sx={formGrid} columnSpacing={4} rowSpacing={2}>
        <Grid item sm={4}>
          <ErasableTextField
            id="first_name"
            label={t("firstName")}
            fullWidth
            isRequired
            hasError={errors?.first_name}
            defaultValue={currentGuardian.first_name}
          />

          {errors?.first_name && renderError(errors?.first_name.message)}
        </Grid>
        <Grid item sm={4}>
          <ErasableTextField
            id="middle_name"
            label={t("middleName")}
            fullWidth
            isRequired={false}
            hasError={errors?.middle_name}
            defaultValue={currentGuardian.middle_name}
          />

          {errors?.middle_name && renderError(errors?.middle_name.message)}
        </Grid>
        <Grid item sm={4}>
          <ErasableTextField
            id="last_name"
            label={t("lastName")}
            fullWidth
            isRequired
            hasError={errors?.last_name}
            defaultValue={currentGuardian.last_name}
          />

          {errors?.last_name && renderError(errors?.last_name.message)}
        </Grid>
        <Grid item sm={6}>
          <PhoneInput
            id="cell_phone"
            label={t("cellPhone")}
            defaultValue={currentGuardian?.cell_phone}
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <PhoneInput
            id="secondary_phone"
            label={t("secondaryPhone")}
            defaultValue={currentGuardian?.secondary_phone}
            fullWidth
          />
        </Grid>
        <Grid item sm={12}>
          <ErasableTextField
            id="email"
            label={t("email")}
            fullWidth
            type="email"
            hasError={errors?.email}
            defaultValue={currentGuardian.email}
          />

          {errors?.email && renderError(errors?.email.message)}
        </Grid>
        <Grid item sm={8}>
          <ErasableTextField
            id="home_church"
            label={t("homeChurch")}
            fullWidth
            type="text"
            hasError={errors?.home_church}
            defaultValue={currentGuardian.home_church}
          />

          {errors?.home_church && renderError(errors?.home_church.message)}
        </Grid>
        <Grid item sm={4} sx={{ mt: "6px" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={currentGuardian && currentGuardian.is_adventist}
                {...register("is_adventist", {})}
              />
            }
            label={
              <Typography sx={font17}>
                {isGuardianView ? t("imAdventist") : "Is Adventist"}
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
