import React from "react";
import { useFormContext } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import ErasableTextField from "../../ErasableTextField";
import { renderError } from "../../../utils/constants/forms";
import PhoneInput from "../../PhoneInput";

export default function GeneralInfo({ currentStaff }) {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Grid
        container
        item
        sx={{
          px: 0,
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
        columnSpacing={4}
        rowSpacing={2}
      >
        <Grid item sm={4}>
          <ErasableTextField
            id="first_name"
            label="First Name *"
            fullWidth
            isRequired
            defaultValue={currentStaff?.first_name}
            hasError={errors?.first_name}
          />

          {errors?.first_name && renderError(errors?.first_name.message)}
        </Grid>

        <Grid item sm={4}>
          <ErasableTextField
            id="middle_name"
            label="Middle Name"
            fullWidth
            defaultValue={currentStaff?.middle_name}
            hasError={errors?.middle_name}
          />

          {errors?.middle_name && renderError(errors?.middle_name.message)}
        </Grid>
        <Grid item sm={4}>
          <ErasableTextField
            id="last_name"
            label="Last Name *"
            fullWidth
            isRequired
            defaultValue={currentStaff?.last_name}
            hasError={errors?.last_name}
          />

          {errors?.last_name && renderError(errors?.last_name.message)}
        </Grid>

        <Grid item sm={6}>
          <ErasableTextField
            id="position"
            label="Position"
            fullWidth
            type="phone"
            defaultValue={currentStaff?.position}
            hasError={errors?.position}
          />

          {errors?.position && renderError(errors?.position.message)}
        </Grid>
        <Grid item container columnSpacing={4} md={12}>
          <Grid item sm={6}>
            <ErasableTextField
              id="email"
              label="Email"
              fullWidth
              type="email"
              defaultValue={currentStaff?.email}
              hasError={errors?.email}
            />

            {errors?.email && renderError(errors?.email.message)}
          </Grid>
          <Grid item sm={6}>
            <PhoneInput
              id="cell_phone"
              label="Cell Phone"
              defaultValue={currentStaff?.cell_phone}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
