import React, { useState, useEffect, useContext } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Chip,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import { chipStyles } from "./ContactInformation.style";
import studentsService from "../../../service/studentsService";
import AddressSection from "./AddressSection";
import PhoneInput from "../../PhoneInput";
import ErasableTextField from "../../ErasableTextField";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

function ContactInformation({ student, residesWith, setResidesWith }) {
  const { register, setValue } = useFormContext();
  const [addressDisabled, setAddressDisabled] = useState(true);
  const [residesWithList, setResidesWithList] = useState(
    student.resides_with || []
  );
  const params = useParams();
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_ACCOUNT,
    PERMISSION_TYPES.MANAGE
  );

  useEffect(() => {
    (async () => {
      if (student.resides_with) setValue("resides_with", student.resides_with);
      const response = await studentsService.fetchResidesWithByStudentId(
        params.student_slug
      );
      if (response.data) {
        setResidesWith(response.data);
        const hasNoGuardian = response.data.length === 0;
        const customAddress =
          student.resides_with_id === null &&
          student.mailing_addres_id !== null;
        if (hasNoGuardian || customAddress) setAddressDisabled(false);
      }
    })();
  }, []);

  const getNameOfSelectedGuardian = (value) =>
    residesWith.find((item) => item.id === value)
      ? `${residesWith.find((item) => item.id === value).first_name} ${
          residesWith.find((item) => item.id === value).last_name
        } (${residesWith.find((item) => item.id === value).relationship})`
      : "";

  return (
    <Grid container item>
      <Grid item sm={11} mb={4}>
        <Typography variant="h3">Student Contact Information</Typography>
      </Grid>
      <Grid container item rowSpacing={5} marginLeft="24px">
        <Grid container item sm={12} xs={12} md={12} spacing={2}>
          <Grid item sm={5}>
            <ErasableTextField
              id="primary_email"
              label="Primary Email"
              defaultValue={student.primary_email}
              fullWidth
              disabled={!managePermission}
            />
          </Grid>
          <Grid item sm={5}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={student.disable_email_notifications}
                  {...register("disable_email_notifications")}
                  disabled={!managePermission}
                />
              }
              label="Do not send notifications to primary email"
            />
          </Grid>
          <Grid item sm={5}>
            <ErasableTextField
              id="secondary_email"
              label="Secondary Email"
              defaultValue={student.secondary_email}
              fullWidth
              disabled={!managePermission}
            />
          </Grid>
          <Grid item sm={5}>
            <PhoneInput
              id="phone_number"
              label="Students Mobile Phone"
              defaultValue={student.phone_number}
              disabled={!managePermission}
              fullWidth
              autoSave
            />
          </Grid>
        </Grid>
        <Grid container item sm={12} xs={12} md={12} spacing={2}>
          <Grid container item sm={10} justifyContent="space-between">
            <FormControl fullWidth>
              <InputLabel id="residesWithLabel">Resides With</InputLabel>
              <Select
                labelId="residesWithLabel"
                multiple
                id="resides_with"
                label="Resides With"
                value={residesWithList}
                disabled={!managePermission}
                {...register("resides_with", {
                  onChange: (event) => {
                    setResidesWithList(event.target.value);
                    setValue("resides_with", event.target.value, {
                      shouldDirty: true,
                    });
                  },
                })}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value, index) => (
                      <Chip
                        key={value}
                        sx={chipStyles}
                        label={getNameOfSelectedGuardian(value)}
                        deleteIcon={
                          <CancelOutlined
                            sx={{ color: "black !important" }}
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={() => {
                          const newValues = [...residesWithList];
                          newValues.splice(index, 1);
                          setResidesWithList(newValues);
                          setValue("resides_with", newValues, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    ))}
                  </Box>
                )}
              >
                {residesWith.map((guardian) => (
                  <MenuItem key={guardian.id} value={guardian.id}>
                    {`${guardian.first_name} ${guardian.last_name} (${guardian.relationship})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {!addressDisabled && (
            <AddressSection student={student} disabled={addressDisabled} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContactInformation;
