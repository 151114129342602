import React from "react";
import { Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import AddressSection from "./AddressSection";
import ErasableTextField from "../../../ErasableTextField";
import PhoneInput from "../../../PhoneInput";

function ContactInformation({ teacher, managePermission }) {
  const { formState } = useFormContext();
  const { errors } = formState;

  return (
    <Grid container item>
      <Grid item sm={11} my={4}>
        <Typography variant="profileTitle">Contact Information</Typography>
      </Grid>
      <Grid container item rowSpacing={5} pl={3}>
        <Grid
          container
          item
          sm={12}
          xs={12}
          md={12}
          spacing={2}
          paddingRight={3}
        >
          <Grid item sm={6}>
            <ErasableTextField
              id="school_email"
              label="School Email"
              type="email"
              hasError={errors?.school_email}
              defaultValue={teacher.school_email}
              fullWidth
              disabled={!managePermission}
            />
          </Grid>
          <Grid item sm={6}>
            <ErasableTextField
              id="personal_email"
              type="email"
              label="Personal Email"
              defaultValue={teacher.personal_email}
              fullWidth
              disabled={!managePermission}
            />
          </Grid>
          <Grid item sm={6}>
            <PhoneInput
              id="mobile_phone"
              label="Mobile Phone"
              defaultValue={teacher.mobile_phone}
              disabled={!managePermission}
              fullWidth
              autoSave
            />
          </Grid>
          <Grid item sm={6}>
            <PhoneInput
              id="work_phone"
              label="Work Phone"
              defaultValue={teacher.work_phone}
              disabled={!managePermission}
              fullWidth
              autoSave
            />
          </Grid>
          <Grid item sm={6}>
            <PhoneInput
              id="home_phone"
              label="Home Phone"
              defaultValue={teacher.home_phone}
              disabled={!managePermission}
              fullWidth
              autoSave
            />
          </Grid>
        </Grid>
        <Grid container item sm={12} xs={12} md={12} spacing={2}>
          {true && (
            <AddressSection teacher={teacher} disabled={!managePermission} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContactInformation;
