import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  Box,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import ErasableTextField from "../../../../../ErasableTextField";
import {
  formGrid,
  generalInfoGrid,
  imageContainer,
  boxContainer,
  profileImage,
  profileImageButton,
} from "./EmergencyContactForm.styles";
import { bgWhite } from "../../../../../sharedStyles";
import {
  requiredMessage,
  renderError,
} from "../../../../../../utils/constants/forms";
import lookupService from "../../../../../../service/lookupService";
import PhoneInput from "../../../../../PhoneInput";

export default function GeneralInfo({ currentContact }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [relationshipList, setRelationshipList] = useState([]);

  const [profilePictureSrc, setProfilePictureSrc] = useState(
    "/assets/default_avatar.jpg"
  );

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePictureSrc(URL.createObjectURL(file));
    }
  };

  const getGuardiansList = async () => {
    const response = await lookupService.fetchStaffRelationships();
    setRelationshipList(response.data.data);
  };

  useEffect(() => {
    if (currentContact?.image_url) {
      setProfilePictureSrc(currentContact?.image_url.url);
    }
  }, [currentContact]);

  useEffect(() => {
    getGuardiansList();
  }, []);

  return (
    <Box>
      <Grid container item sx={generalInfoGrid}>
        <Grid item container direction="row" sm={7}>
          <Grid
            container
            item
            columnSpacing={2}
            rowSpacing={2}
            marginBottom={5}
          >
            <Grid item sm={10}>
              <FormControl fullWidth error={errors?.relationship && true}>
                <InputLabel id="relationshipToStudent" shrink sx={bgWhite}>
                  Relationship *
                </InputLabel>
                <Select
                  labelId="relationshipToStudent"
                  id="relationshipToStudent"
                  defaultValue={currentContact?.relationship || ""}
                  {...register("relationship", {
                    required: requiredMessage,
                  })}
                >
                  <MenuItem disabled value="">
                    Select Relationship
                  </MenuItem>
                  {relationshipList.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {errors?.relationship &&
                renderError(errors?.relationship.message)}
            </Grid>

            <Grid item sm={9}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={currentContact?.is_primary || false}
                      {...register("is_primary", {})}
                    />
                  }
                  label={<Typography>Is the primary contact</Typography>}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="column" sm={5}>
          <Grid container item sm={12} sx={imageContainer}>
            <Box sx={boxContainer}>
              <img alt="profile" src={profilePictureSrc} style={profileImage} />
              <Button
                variant="outlined"
                sx={profileImageButton}
                component="label"
              >
                Add
                <input
                  hidden
                  accept=".jpeg, .jpg, .png"
                  type="file"
                  {...register("profile_picture", {
                    onChange: handleProfilePictureChange,
                  })}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item sx={formGrid} columnSpacing={4} rowSpacing={2}>
        <Grid item sm={4}>
          <ErasableTextField
            id="first_name"
            label="First Name *"
            fullWidth
            isRequired
            defaultValue={currentContact?.first_name}
            hasError={errors?.first_name}
          />

          {errors?.first_name && renderError(errors?.first_name.message)}
        </Grid>

        <Grid item sm={4}>
          <ErasableTextField
            id="middle_name"
            label="Middle Name"
            fullWidth
            defaultValue={currentContact?.middle_name}
            hasError={errors?.middle_name}
          />

          {errors?.middle_name && renderError(errors?.middle_name.message)}
        </Grid>
        <Grid item sm={4}>
          <ErasableTextField
            id="last_name"
            label="Last Name *"
            fullWidth
            isRequired
            defaultValue={currentContact?.last_name}
            hasError={errors?.last_name}
          />

          {errors?.last_name && renderError(errors?.last_name.message)}
        </Grid>

        <Grid item sm={6}>
          <PhoneInput
            id="cell_phone"
            label="Cell Phone"
            defaultValue={currentContact?.cell_phone}
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <PhoneInput
            id="secondary_phone"
            label="Secondary Phone"
            defaultValue={currentContact?.secondary_phone}
            fullWidth
          />
        </Grid>

        <Grid item sm={8}>
          <ErasableTextField
            id="email"
            label="Email *"
            fullWidth
            isRequired
            type="email"
            defaultValue={currentContact?.email}
            hasError={errors?.email}
          />

          {errors?.email && renderError(errors?.email.message)}
        </Grid>
      </Grid>
    </Box>
  );
}
